import React from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component';


const PageHeader = loadable(() => import('../components/PageHeader'));
const VideoSection = loadable(() => import('../components/Accueil/VideoSection'));
const EquipeSection = loadable(() => import('../components/Accueil/EquipeSection'));
const ContacterSection = loadable(() => import('../components/Accueil/ContacterSection'));
const ActualitesSection = loadable(() => import('../components/Accueil/ActualitesSection'));
const SoinsSection = loadable(() => import('../components/Accueil/SoinsSection'));
const Layout = loadable(() => import('../components/Layout'));

import './AccueilPage.scss';

// Export Template for use in CMS preview
export const AccueilPageTemplate = ({title, subtitle, featuredImage, videoSrc, videoText, imageEquipe, phoneNumber, email, hours, soins, posts}) => (
    <main className="Accueil">
        <PageHeader
            large
            button="NOUS CONTACTER"
            title={title}
            subtitle={subtitle}
            backgroundImage={featuredImage}
        />

        <section className="section">
            <VideoSection
                title="Votre cabinet dentaire à Muret"
                videoSrc={videoSrc}
                videoText={videoText}
            />
            <SoinsSection title="Nos soins dentaires"
                          subtitle="Découvrez l'ensemble des soins dentaires proposés par le cabinet" soins={soins}/>
            <EquipeSection title="Une équipe à votre service" subtitle="Une équipe multi-spécialiste pour vous soigner"
                           image={imageEquipe}/>
            <ActualitesSection title="Dernières Actualités" posts={posts}/>
            <ContacterSection title="Nous contacter" phoneNumber={phoneNumber} hours={hours} email={email}/>
        </section>
    </main>
);

// Export Default AccueilPage for front-end
const AccueilPage = ({data: {page, soins, posts, membres}}) => (
    <Layout meta={page.frontmatter.meta || false}>
        <AccueilPageTemplate {...page} {...page.frontmatter} body={page.html}
                             membres={membres.edges.map(membre => ({
                                 ...membre.node,
                                 ...membre.node.frontmatter,
                                 ...membre.node.fields
                             }))}
                             soins={soins.edges.map(soin => ({
                                 ...soin.node,
                                 ...soin.node.frontmatter,
                                 ...soin.node.fields
                             }))}
                             posts={posts.edges.map(post => ({
                                 ...post.node,
                                 ...post.node.frontmatter,
                                 ...post.node.fields
                             }))}/>
    </Layout>
);

export default AccueilPage

export const pageQuery = graphql`
  ## Query for AccueilPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AccueilPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        videoSrc
        videoText
        imageEquipe
        featuredImage
        phoneNumber
        email
        hours {
           day
           hours
         }
      }
    }
    
    soins: allMarkdownRemark(filter: {fields: {contentType: {in: "soins"}}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              showInHomePage
              featuredImage
            }
          }
        }
    }
    
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "actualites" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    
    membres: allMarkdownRemark(filter: {fields: {contentType: {in: "membres"}}}) {
        edges {
          node {
            frontmatter {
              name
              firstname
              job
            }
          }
        }
    }
  }
`;
